<template>
    <el-dialog :title="title" width="40%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form size="small" :model="inputForm" ref="inputForm" :rules="dataRule" v-loading="loading" label-width="120px">
            <el-form-item label="名称：" prop="basicName">
                <el-input v-model.trim="inputForm.basicName" maxlength="10" placeholder="请输入元数据名称（限10字）" clearable class="w90" @input="nameChange"></el-input>
            </el-form-item>
            <el-form-item label="数据类型：" prop="basicTypeId">
                <el-select v-model="inputForm.basicTypeId" placeholder="请选择元数据类型" clearable class="w90">
                    <el-option v-for="item in basicTypeOpt" :key="item.id" :label="item.archivesBasicType"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否必选：" prop="required">
                <el-radio-group v-model="inputForm.required">
                    <el-radio :label="item.value" :key="item.value" v-for="item in $dictUtils.getDictList('public_state')">{{item.label}}</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    basicName: '',
                    basicTypeId: '',
                    required: '0',
                },
                basicTypeOpt: [],
                dataRule: {
                    basicName: [
                        {required: true, message: '名称不能为空', trigger: 'blur'},
                        {validator: this.validator.isEmoji, trigger:'blur'}
                    ],
                    basicTypeId: [
                        {required: true, message: '数据类型不能为空', trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            init (row, method) {
                this.getArchives()
                this.loading = false
                this.method = method
                if (method === 'add') {
                    this.title = '新增元数据'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            // 获取数据类型
            getArchives() {
                this.$axios(this.api.collection.getAllArchivesBasicType).then((res) => {
                    if (res.status) {
                        this.basicTypeOpt = res.data
                    }
                })
            },
            nameChange(val) {
                if(val.length >= 50) {
                    this.$message.warning('输入已达上限！')
                }
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.collection.archivesSave, this.inputForm, 'post').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }else{
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
